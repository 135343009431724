import cx from 'classnames';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import { ChainName } from 'types/emoney/Chain/indexV2';
import s from './Icon.less';

export function getSrc(chainName: ChainName, isLarge: boolean) {
  return `/assets/emoney/chains/${chainName}-icon-${isLarge ? 'lg' : 'sm'}.svg`;
}

const Icon = ({
  className = '',
  isLarge = false,
  chainName,
}: {
  className?: string;
  isLarge?: boolean;
  chainName?: ChainName;
}) => {
  useStyles(s);
  return chainName ? (
    <img
      className={cx(className, s.image)}
      src={getSrc(chainName, isLarge)}
      alt={chainName}
      title={chainName}
    />
  ) : (
    <span className="material-icons" title={chainName}>
      question_mark
    </span>
  );
};

export default Icon;
