import { useNotification } from 'components/Notification/hooks';
import { useConnect, useDisconnect } from 'components/Wallet/hooks';
import { useState } from 'react';
import { useLogger } from 'services/logger';
import { Chain, EVMChainId } from 'types/emoney/Chain/indexV2';
import { Connector, useConnect as useWagmiConnect } from 'wagmi';
import ButtonGroup from '../../Buttons/ButtonGroup';
import WalletOption from './EvmOptions';

const connectorOrder = [
  'metaMask',
  'injected',
  'walletConnect',
  'coinbaseWalletSDK',
  'safe',
];

const sortConnectors = (connectors: readonly Connector[]) => {
  return connectors
    .filter(() => {
      return true;
    })
    ?.sort((a, b) => {
      // sort connectors
      const indexA = connectorOrder.indexOf(a.id);
      const indexB = connectorOrder.indexOf(b.id);

      // If the connector id is not in the predefined order, place it at the end
      const orderA = indexA === -1 ? connectorOrder.length : indexA;
      const orderB = indexB === -1 ? connectorOrder.length : indexB;

      return orderA - orderB;
    });
};

const EvmSelect = ({
  chain,
  handleSuccess,
}: {
  chain: Chain;
  handleSuccess: (wallet: string) => void;
}) => {
  const { connectors } = useWagmiConnect();
  const { connect } = useConnect('evm');
  const { disconnect } = useDisconnect('evm');
  const { logMessage } = useLogger();
  const { notifyError } = useNotification();
  const [isLoading, setIsLoading] = useState(false);

  const sortedConnectors = sortConnectors(connectors);

  return (
    <>
      <ButtonGroup>
        {sortedConnectors.map((connector) => {
          return (
            <WalletOption
              key={connector.uid}
              isLoading={isLoading}
              connector={connector}
              onClick={async () => {
                setIsLoading(true);
                // Disconnecting before connecting makes it easier to manage.
                await disconnect({ connector });

                await connect(
                  { connector, chainId: chain?.chainId as EVMChainId },
                  {
                    onSuccess: async () => {
                      handleSuccess(connector.id);
                      setIsLoading(false);
                    },
                    onError: async (e: Error) => {
                      if (e?.message === 'User rejected the request') {
                        notifyError(e?.message);
                      } else if (e?.message === 'ChainId not found') {
                        notifyError(
                          `ChainId ${chain?.chainId} not recognized by wallet.`,
                        );
                      } else {
                        notifyError(e?.message);
                        logMessage({
                          message: 'Could not connect: ' + e?.message,
                        });
                      }
                      setIsLoading(false);
                    },
                  },
                );
              }}
            />
          );
        })}
      </ButtonGroup>
    </>
  );
};
export default EvmSelect;
